import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import http from '../api/http-common';
import OrderServices from '../api/OrderServices';

const initialState = {
  data: [],
};

export const createOrder = createAsyncThunk('order', async (data) => {
  try {
    const res = await OrderServices.create(data);
    return res.data;
  } catch (error) {
    if (!error.response.data.message) {
      toast.error(error.message, { position: 'top-right' });
    } else {
      toast.error(error.response.data.message, { position: 'top-right' });
    }
    throw error;
  }
});

export const deleteOrder = createAsyncThunk('order', async (data) => {
  try {
    const res = await OrderServices.deleteOrder(data);
    return res.data;
  } catch (error) {
    if (!error.response.data.message) {
      toast.error(error.message, { position: 'top-right' });
    } else {
      toast.error(error.response.data.message, { position: 'top-right' });
    }
    throw error;
  }
});

export const allDeleteOrder = createAsyncThunk('order', async (data) => {
  try {
    const res = await OrderServices.allDeleteOrder(data);
    return res.data;
  } catch (error) {
    if (!error.response.data.message) {
      toast.error(error.message, { position: 'top-right' });
    } else {
      toast.error(error.response.data.message, { position: 'top-right' });
    }
    throw error;
  }
});

export const updateorder = createAsyncThunk('order/updateAPI', async (payload) => {
  try {
    const response = await http.post(`/choosePaymentMethod`, payload);
    return response.data;
  } catch (error) {
    if (!error.response.data.message) {
      toast.error(error.message, { position: 'top-right' });
    } else {
      toast.error(error.response.data.message, { position: 'top-right' });
    }
    throw error;
  }
});
export const getOrderHistory = createAsyncThunk('order/HistoryAPI', async () => {
  try {
    const response = await http.get(`/getOrderHistory`);
    return response.data;
  } catch (err) {
    console.log(err.message);
    throw err;
  }
});
// export const getOrderDetailByOrderId= createAsyncThunk(
//   "order/DetailsAPIByOrderID",
//   async (orderid) => {
//     const response = await axios.get(
//       `${api}/order-details/${orderid}`
//     );
//     return response.data;
//   }
// );
const orderSlice = createSlice({
  name: 'order',
  initialState,
  extraReducers: {
    [createOrder.fulfilled]: (state, action) => {
      state.push(action.payload);
    },
    [updateorder.fulfilled]: (state, action) => {
      state.data = state.data.filter((_) => _._id !== action.payload.data._id);
      state.data.unshift(action.payload);
    },
    [getOrderHistory.fulfilled]: (state, action) => {
      return action.payload;
    },
    // [getOrderDetailByOrderId.fulfilled]:(state,action)=>{
    //   return action.payload;
    // }
  },
});

export const { orderAdded } = orderSlice.actions;
export default orderSlice.reducer;
