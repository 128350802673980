import { Box, Button, Card, Grid, List, ListItem, Stack, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import PhoneIcon from '@mui/icons-material/Phone';
import PersonIcon from '@mui/icons-material/Person';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import ProfileNavBar from './ProfileNavBar';
import imgmap from '../../Assets/image 24.png';
import { BlueButton, BootstrapDialog } from '../../Components/Components';
import { allDeleteOrder, deleteOrder, getOrderHistory } from '../../redux/slice/orderSlice';
import { getUserDetails } from '../../redux/slice/userSlice';
import { addToCart } from '../../redux/slice/cartSlice';
import OrderServices from '../../redux/api/OrderServices';
import { imageBaseUrl } from '../../redux/api/http-common';

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const OrderHistoryPage = () => {
  const uid = localStorage.getItem('user');
  const cachedData = useSelector((state) => state.clientorder);
  const data = React.useMemo(() => cachedData, [cachedData]);
  const cachedUserData = useSelector((state) => state.clientuser.userInfo);
  const userInfo = React.useMemo(() => cachedUserData, [cachedUserData]);
  const [productItems, setProductItem] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [isReview, setIsReview] = React.useState(false);
  const [reFetch, setReFetch] = React.useState(false);
  const [items, setItems] = useState(null);
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    dispatch(getOrderHistory());
    dispatch(getUserDetails());
  }, [dispatch]);

  const initFetch = React.useCallback(() => {
    dispatch(getUserDetails());
    dispatch(getOrderHistory(uid));
  }, [dispatch]);

  useEffect(() => {
    initFetch();
  }, [initFetch, reFetch]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    items?.products.map((resp) =>
      setProductItem((arr) => [
        ...arr,
        {
          product_id: resp.product_id._id,
          quantity: resp.quantity,
          new_price: resp.product_id.new_price,
          old_price: resp.product_id.old_price,
          discount: resp.product_id.discount,
          selectedSize: !resp.selectedSize ? null : resp.selectedSize,
        },
      ])
    );
  }, [items]);

  const addToCartData = () => {
    items?.products?.forEach((res) => {
      const products = {
        products: res.product_id,
        cartQty: res.quantity,
        selectedSize: !res.selectedSize ? null : res.selectedSize,
        new_price: res.new_price,
        old_price: res.old_price,
        discount: res.discount,
      };
      dispatch(addToCart(products));
    });
    navigate('/checkout');
  };

  const onSubmit = async () => {
    let subTotals = 0;
    let shipFee = 0;
    items?.products.forEach((resp) => {
      subTotals += resp.product_id.new_price * resp.quantity;
      shipFee += resp.product_id.shiping_fee * resp.quantity;
    });
    const formData = new FormData();
    formData.append('name', userInfo?.name);
    formData.append('phone', userInfo?.phone);
    formData.append('delivery_address', items.delivery_address);
    formData.append('sub_total', subTotals);
    // formData.append("total_shipping_fee", shipFee);
    formData.append('all_totals', Number(subTotals) + Number(shipFee));
    formData.append('products', JSON.stringify(productItems));
    formData.append('customQuantity', 0);
    formData.append('customTotal', 0);
    formData.append('customProduct', '[]');
    try {
      const res = await OrderServices.create(formData);
      if (res.data) {
        localStorage.setItem('orderitem', JSON.stringify(res.data.data));
        toast.success(res.data.message, {
          position: 'bottom-left',
        });
        navigate('/payment');
        setProductItem([]);
      }
    } catch (error) {
      if (!error.response.data.message) {
        toast.error(error.message, { position: 'top-right' });
      } else {
        toast.error(error.response.data.message, { position: 'top-right' });
      }
    }
  };

  const handleDeleteOrder = async (id) => {
    try {
      const res = await dispatch(deleteOrder(id));

      if (res.payload.success) {
        setReFetch(!reFetch);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAllDeleteOrder = async () => {
    try {
      const res = await dispatch(allDeleteOrder());

      if (res.payload.success) {
        setReFetch(!reFetch);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancelOrder = async (id) => {
    await OrderServices.orderCancel(id, { status: 'Cancelled' })
      .then((res) => {
        toast.success(res?.data?.message);
      })
      .catch((error) => {
        if (!error?.response?.data) {
          toast.error(error.message);
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };

  return (
    <>
      <Grid container className="profile-container" spacing={2}>
        <Grid item xs={10} sm={10} md={2} lg={2} xl={2}>
          <ProfileNavBar />
        </Grid>
        <Grid item xs={10} sm={10} md={8} lg={8} xl={8}>
          <Box display="flex" justifyContent="space-between">
            <Typography className="prfile-title">Order History</Typography>
            <Button variant="contained" onClick={handleAllDeleteOrder}>
              Delete All
            </Button>
          </Box>

          <>
            {data?.orders && data?.orders.length !== 0 && Array.isArray(data?.orders) ? (
              data?.orders.map((res, index) => (
                <Grid container spacing={2} key={index} py={2}>
                  {res?.products ? (
                    res?.products?.slice(0, 1).map((ress, index) => (
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={index}>
                        <Card className="order-history-card">
                          <Box
                            onClick={() => {
                              setItems(res);
                              handleClickOpen();
                            }}
                            className="order-history-card-content-container"
                          >
                            <img
                              src={imageBaseUrl + ress.product_id.images[0]}
                              alt={ress.product_id._id}
                              height={'200px'}
                              width={'200px'}
                              className="order-history-img"
                              key={ress.id}
                            />
                            <Typography className="order-history-card-content-des">
                              {ress.product_id.product_title}
                            </Typography>
                          </Box>

                          <Stack display="flex" justifyContent={'space-between'} p={2}>
                            {res.status === 'Completed' ? (
                              <Typography
                                sx={{
                                  textAlign: 'center',
                                  border: '1px solid gray',
                                  padding: '10px',
                                  borderRadius: '10px',
                                  '&:hover': {
                                    backgroundColor: 'black',
                                    color: 'white',
                                  },
                                }}
                                onClick={() => {
                                  setItems(res);
                                  setIsReview(true);
                                }}
                              >
                                {res.status === 'Completed' ? 'Write a review' : ''}
                              </Typography>
                            ) : null}
                            <Stack spacing={2}>
                              {res.status === 'Pending' ||
                              res.status === 'Completed' ||
                              res.status === 'Cancelled' ||
                              res.status === 'Return' ? (
                                <Typography
                                  sx={{
                                    textAlign: 'center',
                                    border: '1px solid red',
                                    padding: '10px',
                                    borderRadius: '10px',
                                    '&:hover': {
                                      backgroundColor: 'red',
                                      color: 'white',
                                    },
                                  }}
                                  onClick={() => {
                                    // setItems(res);
                                    // setIsReview(true);
                                    handleDeleteOrder(res._id);
                                  }}
                                >
                                  {res.status === 'Pending' ||
                                  res.status === 'Completed' ||
                                  res.status === 'Cancelled' ||
                                  res.status === 'Return'
                                    ? 'Delete Order'
                                    : ''}
                                </Typography>
                              ) : null}
                              {res.status === 'Pending' ? (
                                <Typography
                                  sx={{
                                    textAlign: 'center',
                                    border: '1px solid red',
                                    padding: '10px',
                                    borderRadius: '10px',
                                    '&:hover': {
                                      backgroundColor: 'red',
                                      color: 'white',
                                    },
                                  }}
                                  onClick={() => {
                                    // setItems(res);
                                    // setIsReview(true);
                                    handleCancelOrder(res._id);
                                  }}
                                >
                                  {res.status === 'Pending' ? 'Cancel Order' : ''}
                                </Typography>
                              ) : null}
                              <Typography className="order-history-card-content-date">
                                {moment(new Date(res.createdAt).toISOString().substring(0, 10)).format('LL')}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Card>
                      </Grid>
                    ))
                  ) : (
                    <p>No product</p>
                  )}
                </Grid>
              ))
            ) : (
              <p>Empty...</p>
            )}
            <Box sx={{ width: '1000px' }}>
              <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                  Order Details
                </BootstrapDialogTitle>
                <DialogContent dividers>
                  {items !== null ? (
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={10}
                        sm={10}
                        md={10}
                        lg={10}
                        xl={10}
                        className="order-history-dialog-content-container"
                      >
                        <Typography className="order-history-dialog-content-title">Delivery to</Typography>
                        <Typography className="order-history-dialog-content-title2" onClick={addToCartData}>
                          Add new address
                        </Typography>
                      </Grid>
                      <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                        <Grid container className="order-histroy-dialog-add-container">
                          <Grid item xs={12} sm={12} md={2} lg={2} xl={2} p={1}>
                            <img src={imgmap} alt="map img" width={'100px'} />
                          </Grid>
                          <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                            <List sx={{ paddingLeft: '25px' }}>
                              <ListItem>
                                <LocationOnIcon className="order-history-dialog-map-address-icon" />
                                <Typography className="order-history-dialog-map-address-des">
                                  {items.delivery_address}
                                </Typography>
                              </ListItem>
                              <ListItem>
                                <PersonIcon className="order-history-dialog-map-address-icon" />
                                <Typography className="order-history-dialog-map-address-des">
                                  {userInfo === null ? null : userInfo.first_name}{' '}
                                  {userInfo === null ? null : userInfo.last_name}
                                </Typography>
                              </ListItem>
                              <ListItem>
                                <PhoneIcon className="order-history-dialog-map-address-icon" />
                                <Typography className="order-history-dialog-map-address-des">
                                  {userInfo === null ? null : userInfo.phone}
                                </Typography>
                              </ListItem>
                            </List>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={10}
                        sm={10}
                        md={7}
                        lg={7}
                        xl={7}
                        className="order-history-dialog-content-container"
                      >
                        <Typography className="order-history-dialog-content-title">Delivery Time</Typography>
                        <Typography className="order-history-dialog-map-address-des">
                          {moment(items.createdAt).format('HH:mm a')}
                        </Typography>
                        <Typography className="order-history-dialog-map-address-des">
                          {moment(items.createdAt).format('LL')}
                        </Typography>
                      </Grid>
                      {items.products ? (
                        items.products?.map((res) => (
                          <Grid
                            item
                            xs={10}
                            sm={10}
                            md={7}
                            lg={7}
                            xl={7}
                            className="order-history-dialog-content-container"
                            key={res._id}
                          >
                            <Grid container>
                              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} key={res._id}>
                                <img src={imageBaseUrl + res.product_id.images[0]} alt="image4" width={'100%'} />
                              </Grid>
                              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} p={3}>
                                <Typography className="order-history-dialog-content-title">
                                  {res.product_title}
                                </Typography>
                                <Typography className="order-history-dialog-map-address-des" pt={1} pl={0}>
                                  Rs. {parseFloat(res.new_price).toFixed(2)}
                                </Typography>
                                <Typography className="order-history-dialog-map-address-des" pt={1} pl={0}>
                                  Qty: {res.quantity}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))
                      ) : (
                        <p>Loading</p>
                      )}
                      <Grid
                        item
                        xs={10}
                        sm={10}
                        md={7}
                        lg={7}
                        xl={7}
                        className="order-history-dialog-content-container"
                      >
                        <Typography className="order-history-dialog-map-address-des">Subtotal</Typography>
                        <Typography className="order-history-dialog-map-address-des">
                          Rs:{' '}
                          {(items === null && items.sub_total === undefined) || items.sub_total === null
                            ? null
                            : parseFloat(items.sub_total).toFixed(2)}
                        </Typography>
                      </Grid>
                      {/* <Grid
                      item
                      xs={10}
                      sm={10}
                      md={7}
                      lg={7}
                      xl={7}
                      className="order-history-dialog-content-container"
                    >
                      <Typography className="order-history-dialog-map-address-des">
                        Ship fee
                      </Typography>
                      <Typography className="order-history-dialog-map-address-des">
                        Rs:{" "}
                        {(items == null &&
                          items.total_shipping_fee == undefined) ||
                          items.total_shipping_fee == null
                          ? null
                          : items.total_shipping_fee}
                      </Typography>
                    </Grid> */}
                      <Grid
                        item
                        xs={10}
                        sm={10}
                        md={7}
                        lg={7}
                        xl={7}
                        className="order-history-dialog-content-container"
                      >
                        <Typography className="order-history-dialog-map-price-total">Total</Typography>
                        <Typography className="order-history-dialog-map-price-total">
                          Rs:{' '}
                          {(items === null && items.all_totals === undefined) || items.all_totals === null
                            ? null
                            : parseFloat(items.all_totals).toFixed(2)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography className="co-header-text-font6" sx={{ color: 'red !important' }}>
                          Note: Ship Fee Calculate on the base of Distance and Weight.
                        </Typography>
                        <BlueButton className="order-history-dialog-btn" onClick={onSubmit}>
                          Re-Order
                        </BlueButton>
                      </Grid>
                    </Grid>
                  ) : null}
                </DialogContent>
              </BootstrapDialog>
            </Box>
          </>
        </Grid>
      </Grid>
      <BootstrapDialog onClose={() => setIsReview(false)} aria-labelledby="customized-dialog-title" open={isReview}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setIsReview(false)}>
          Write a review
        </BootstrapDialogTitle>
        <DialogContent dividers>
          {items !== null ? (
            <Grid container spacing={2}>
              {items.products ? (
                items.products?.map((res) => (
                  <Grid
                    item
                    xs={10}
                    sm={10}
                    md={7}
                    lg={7}
                    xl={7}
                    className="order-history-dialog-content-container"
                    key={res._id}
                  >
                    <Grid container>
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4} key={res._id}>
                        <img src={imageBaseUrl + res.product_id.images[0]} alt="image4" width={'100%'} />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6} p={3}>
                        <Typography className="order-history-dialog-content-title">{res.product_title}</Typography>
                        <Typography className="order-history-dialog-map-address-des" pt={1} pl={0}>
                          Rs. {parseFloat(res.new_price).toFixed(2)}
                        </Typography>
                        <Typography className="order-history-dialog-map-address-des" pt={1} pl={0}>
                          Qty: {res.quantity}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={2} lg={2} xl={2} p={3}>
                        <Typography
                          component={Link}
                          to={`/products/${res?.product_id?.product_title?.replace(/\s+/g, '-')?.toLowerCase()}/${
                            res?.product_id?._id
                          }`}
                          state={{ id: res._id, isOrderCompleted: true }}
                          sx={{ cursor: 'pointer' }}
                          className="order-history-dialog-content-title"
                        >
                          Review
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))
              ) : (
                <p>Loading</p>
              )}
              <Grid item xs={10} sm={10} md={7} lg={7} xl={7} className="order-history-dialog-content-container">
                <Typography className="order-history-dialog-map-address-des">Subtotal</Typography>
                <Typography className="order-history-dialog-map-address-des">
                  Rs:{' '}
                  {(items === null && items.sub_total === undefined) || items.sub_total === null
                    ? null
                    : parseFloat(items.sub_total).toFixed(2)}
                </Typography>
              </Grid>

              <Grid item xs={10} sm={10} md={7} lg={7} xl={7} className="order-history-dialog-content-container">
                <Typography className="order-history-dialog-map-price-total">Total</Typography>
                <Typography className="order-history-dialog-map-price-total">
                  Rs:{' '}
                  {(items === null && items.all_totals === undefined) || items.all_totals === null
                    ? null
                    : parseFloat(items.all_totals).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          ) : null}
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default OrderHistoryPage;
