import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import {
  Grid,
  Divider,
  Typography,
  Button,
  Box,
  Pagination,
  PaginationItem,
  Rating,
  TextField,
  Checkbox,
  Breadcrumbs,
  IconButton,
  Stack,
} from '@mui/material';

import FavoriteIcon from '@mui/icons-material/Favorite';
import ChatIcon from '@mui/icons-material/Chat';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import moment from 'moment/moment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { getOrderHistory } from '../../redux/slice/orderSlice';
import { createReview, updateReview } from '../../redux/slice/reviewSlice';
import FeaturedProductServices from '../../redux/api/FeaturedProductServices';
import SameStoreServices from '../../redux/api/SameStoreServices';
import { addToCart } from '../../redux/slice/cartSlice';
import ReviewServices from '../../redux/api/ReviewServices';
import { addFavourite, deleteFavourite } from '../../redux/slice/favouriteSlice';
import { getUserDetails } from '../../redux/slice/userSlice';
import ChatDialog from '../Chat/ChatDialog';
import FavouriteService from '../../redux/api/FavouriteService';
import ProductCard from '../../Components/ProductCard';
import { imageBaseUrl } from '../../redux/api/http-common';
import { BlueButton, GreenButton } from '../../Components/Components';
import ClientProductSEOService from '../../redux/api/ClientProductSEOService';
import ProgressBar from '../../../components/progress/ProgressBar';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const initialValues = {
  message: '',
};

const ProductDetail = () => {
  const { id } = useParams();
  // const id = location.state.id;
  const dispatch = useDispatch();

  const clientOrders = useSelector((state) => state.clientorder.orders);

  const [counter, setCounter] = useState(1);
  const [reviewIcon, setReviewIcon] = useState(<ChevronRightIcon />);
  const [isShown, setIsShown] = useState(false);
  const navigate = useNavigate();
  const [fetchProduct, setFetchProduct] = useState([]);

  const [imageProduct, setImageProduct] = useState([]);
  const [selectedSize, setSelectedSize] = useState(null);
  const [fetchSameStore, setSameStore] = useState([]);
  const [getReview, setGetReview] = useState([]);
  const [checkeds, setChecked] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [imgUrls, setImageUrl] = useState(null);
  const [seoDat, setSeoData] = useState(null);
  const [editReview, setEditReview] = useState(false);
  const [reviewId, setReviewId] = useState();
  const [oldPrice, setOldPrice] = useState(0);
  const [newPrice, setNewPrice] = useState(0);
  const [discount, setDiscount] = useState(0);

  // Pagenations
  const [currentPage, setCurrentPage] = useState(1);
  const [productPerPage] = useState(6);
  // Chat
  const [openChat, setOpenChat] = useState(false);

  const handleChat = () => {
    setOpenChat(!openChat);
  };

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validations({ [name]: value });
  };
  const validations = (fieldValue = values) => {
    const temp = { ...errors };
    if ('message' in fieldValue) temp.message = fieldValue.message ? '' : 'This field requires';

    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === '');
  };

  // Favourite
  const userToken = useSelector((state) => state.clientuser.token);
  const userInfo = useSelector((state) => state.clientuser.userInfo);

  // automatically authenticate user if token is found
  useEffect(() => {
    if (userToken) {
      dispatch(getUserDetails());
    }
  }, [userToken, dispatch]);

  useEffect(() => {
    dispatch(getOrderHistory());
  }, [dispatch, userInfo]);

  useEffect(() => {
    FavouriteService.get(id)
      .then((res) => {
        setChecked(res.data.status);
      })
      .catch((error) => {
        console.log(error.message);
        setChecked(false);
      });
  }, [userInfo]);

  const totalProducts = getReview?.length; // Total number of products
  const totalPages = Math.ceil(totalProducts / productPerPage); // Calculate total pages
  const indexOfLastPost = currentPage * productPerPage;
  const indexOfFirstPost = indexOfLastPost - productPerPage;
  const currentPosts = getReview?.slice(indexOfFirstPost, indexOfLastPost);

  // Product Detail
  const getDetails = async (id) => {
    await FeaturedProductServices.get(id)
      .then((res) => {
        setFetchProduct(!res.data.data ? [] : res.data.data);
        const filteredSizes =
          !res?.data?.data?.sizes || res?.data?.data?.sizes.length === 0
            ? []
            : res?.data?.data?.sizes.filter((item) => item.itemStatus === false || item.itemStatus === 'false');
        setSelectedSize(!filteredSizes || filteredSizes.length === 0 ? null : filteredSizes[0]);
        setImageProduct(!res.data.data.images ? [] : res.data.data.images);
        setImageUrl(!res.data.data.images[0] ? '' : res.data.data.images[0]);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  // Same Store

  const samestore = async (id) => {
    await SameStoreServices.get(id).then((res) => {
      setSameStore(res.data.data);
    });
  };

  const getReviews = async (id) => {
    await ReviewServices.get(id).then((res) => {
      setGetReview(res.data.data);
    });
  };

  // Product Detail
  useEffect(() => {
    getSEO();
    getDetails(id);
    samestore(id);
  }, [id]);

  useEffect(() => {
    if (!selectedSize || Object.keys(selectedSize).length === 0) {
      setOldPrice(fetchProduct?.old_price);
      setNewPrice(fetchProduct?.new_price);
      setDiscount(fetchProduct?.discount);
    } else {
      setOldPrice(selectedSize?.totalPrice);
      setNewPrice(selectedSize?.newPrice);
      setDiscount(selectedSize?.discount);
    }
  }, [fetchProduct, selectedSize]);

  //  Post Review
  const [feedbackImages, setfeedbackImages] = useState(null);
  const [ratingValue, setRatingValue] = useState();
  const [fileError, setFileError] = useState(false);

  const saveReview = async () => {
    if (!feedbackImages) {
      setFileError(true);
      return;
    }
    if (!clientOrders || clientOrders.length === 0) {
      toast.error('You cannot order the product. please first you can place an order.', {
        position: 'bottom-left',
      });
      return;
    }
    const filteredOrders = clientOrders?.find(
      (order) => order.status === 'Completed' && order.products.find((items) => items?.product_id?._id === id)
    );
    if (!filteredOrders || filteredOrders.length === 0) {
      toast.error('Your order status is not completed. please contact to admin for review.', {
        position: 'bottom-left',
      });
      return;
    }
    const formdata = new FormData();
    formdata.append('comment', values.message);
    formdata.append('rating', ratingValue);
    formdata.append('feedbackImage', feedbackImages);
    formdata.append('orderId', filteredOrders?._id);
    formdata.append('productId', id);
    if (validations()) {
      await dispatch(createReview(formdata))
        .unwrap()
        .then(() => {
          toast.success('Your Review Submit Successfully', {
            position: 'bottom-left',
          });
          setEditReview(true);
          setValues(initialValues);
          getReviews(id);
          setfeedbackImages(null);
          setRatingValue(0);
          getReviews(id);
          setIsShown(!isShown);
        })
        .catch((e) => {
          toast.success(e.response.data.message, {
            position: 'bottom-left',
          });
        });
    }
  };

  const handleEditReview = async () => {
    if (!feedbackImages) {
      setFileError(true);
      return;
    }
    const filteredOrders = clientOrders?.find(
      (order) => order.status === 'Completed' && order.products.find((items) => items?.product_id?._id === id)
    );
    const formdata = new FormData();
    formdata.append('comment', values.message);
    formdata.append('rating', ratingValue);
    formdata.append('feedbackImage', feedbackImages);
    formdata.append('orderId', filteredOrders?._id);
    formdata.append('productId', id);
    if (validations()) {
      await dispatch(updateReview({ id: reviewId, data: formdata }))
        .unwrap()
        .then(() => {
          toast.success('Your Review Update Successfully', {
            position: 'bottom-left',
          });
          setValues(initialValues);
          getReviews(id);

          setfeedbackImages(null);
          setRatingValue(0);
          getReviews(id);
          setIsShown(!isShown);
        })
        .catch((error) => {
          if (!error.response.data.message) {
            toast.error(error.message, { position: 'top-right' });
          } else {
            toast.error(error.response.data.message, { position: 'top-right' });
          }
        });
    }
  };

  useEffect(() => {
    getDetails(id);
    samestore(id);
  }, [dispatch, id]);

  useEffect(() => {
    getAllreview(id);
  }, [dispatch, id, userInfo, clientOrders]);

  const handleClick = (event) => {
    // 👇️ toggle shown state
    if (!userInfo || Object.keys(userInfo).length === 0) {
      navigate('/login');
    } else {
      setIsShown(!isShown);
      // isShown === true ? setReviewIcon(<ExpandMoreIcon />) : setReviewIcon(<ExpandMoreIcon />);
      setReviewIcon(isShown ? <ExpandMoreIcon /> : <ExpandMoreIcon />);
    }
  };

  const increment = () => {
    setCounter(counter + 1);
  };

  const decrement = () => {
    if (counter > 1) setCounter(counter - 1);
  };
  const handleChangeCheckBox = (event) => {
    setChecked(event.target.checked);
    if (checkeds === false) {
      const payload = {
        productId: fetchProduct._id,
        status: true,
      };
      dispatch(addFavourite(payload));
      getDetails(id);
    } else {
      const payload = {
        productId: fetchProduct._id,
        status: false,
      };
      dispatch(deleteFavourite(payload));
      getDetails(id);
    }
  };

  // Add to cart
  const handleAddToCart = (product) => {
    const products = {
      products: product,
      cartQty: counter,
      new_price: newPrice,
      old_price: oldPrice,
      discount,
      selectedSize,
    };
    if (!selectedSize || Object.keys(selectedSize).length === 0) {
      dispatch(addToCart(products));
      navigate('/cart');
    } else if (selectedSize.itemStatus === true || selectedSize.itemStatus === 'true') {
      toast.error('Please select another size. it is outoff stock');
    } else {
      dispatch(addToCart(products));
      navigate('/cart');
    }
  };
  // Add to cart
  const handleBuyNow = (product) => {
    const products = {
      products: product,
      cartQty: counter,
      new_price: newPrice,
      old_price: oldPrice,
      discount,
      selectedSize,
    };
    if (!selectedSize || Object.keys(selectedSize).length === 0) {
      dispatch(addToCart(products));
      navigate('/cart');
    } else if (selectedSize.itemStatus === true || selectedSize.itemStatus === 'true') {
      toast.error('Please select another size. it is outoff stock');
    } else {
      dispatch(addToCart(products));
      navigate('/cart');
    }
  };

  const handleCounterChange = (e) => {
    const inputValue = e.target.value;

    // Remove leading zeros and non-numeric characters
    const sanitizedValue = inputValue.replace(/^0+/, '').replace(/\D/g, '');

    // Make sure the sanitized value is not empty
    if (sanitizedValue !== '') {
      setCounter(Number(sanitizedValue));
    } else {
      setCounter(1); // Reset to 0 if input is empty
    }
  };

  const getSEO = async () => {
    await ClientProductSEOService.getbyid(id)
      .then((res) => {
        setSeoData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllreview = async () => {
    await ReviewServices.get(id)
      .then((res) => {
        const hasFeedbackForProduct = res?.data?.data?.find((feedback) => feedback.productId === id);
        if (hasFeedbackForProduct && hasFeedbackForProduct?.userId?._id === userInfo?._id) {
          const createdAtDate = new Date(hasFeedbackForProduct?.createdAt);
          const currentTime = new Date();
          const timeDifference = currentTime - createdAtDate;
          const maxTimeDifference = 24 * 60 * 60 * 1000;
          const isWithinHours = timeDifference < maxTimeDifference;
          if (isWithinHours && hasFeedbackForProduct?.userId?._id === userInfo?._id) {
            setEditReview(true);
          }
        }
        setGetReview(res.data.data);
      })
      .catch((e) => {
        console.log(e.message);
      });
  };
  return (
    <>
      <Helmet>
        <title>{!seoDat || Object.keys(seoDat).length === 0 || !seoDat?.web_title ? '' : seoDat?.web_title}</title>
        <meta
          name="description"
          content={!seoDat || Object.keys(seoDat).length === 0 || !seoDat?.web_title ? '' : seoDat?.web_description}
        />
        <meta
          property="og:title"
          content={!seoDat || Object.keys(seoDat).length === 0 || !seoDat?.web_title ? '' : seoDat?.web_title}
        />
        <meta
          property="og:description"
          content={!seoDat || Object.keys(seoDat).length === 0 || !seoDat?.web_title ? '' : seoDat?.web_description}
        />
        <meta property="og:image" content={!imageProduct ? null : imageBaseUrl + imgUrls} />
        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="100%" />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      {loading === true ? (
        <ProgressBar />
      ) : (
        <Box>
          {fetchProduct !== null || fetchProduct !== undefined || fetchProduct.length !== 0 ? (
            <Grid container className="top-container centered" spacing={5}>
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <Breadcrumbs separator="-" aria-label="breadcrumb">
                  <Link
                    underline="hover"
                    key="1"
                    color="inherit"
                    to="/"
                    style={{ textDecoration: 'none', color: 'black' }}
                  >
                    Home
                  </Link>
                  <Link
                    underline="hover"
                    key="1"
                    color="inherit"
                    to="/art-print-&-stationery"
                    style={{ textDecoration: 'none', color: 'black' }}
                  >
                    Products
                  </Link>
                  <Typography key="3" color="text.primary">
                    {!fetchProduct?.product_title ? null : fetchProduct?.product_title}
                  </Typography>
                </Breadcrumbs>
              </Grid>
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                {fetchProduct !== null || fetchProduct !== undefined ? (
                  <Grid container spacing={5}>
                    <Grid item xs={12} sm={10} md={5} lg={4} xl={4}>
                      <h1 className="single-prod-title">{fetchProduct?.product_title}</h1>
                      {imageProduct === null || imageProduct === undefined ? null : (
                        <img src={imageBaseUrl + imgUrls} alt="pic" className="prod-image-main" />
                      )}
                      <Divider className="prod-divider" />
                      <Grid container className="prod-image-small-container">
                        {imageProduct !== null || imageProduct !== undefined || imageProduct.length > 0
                          ? imageProduct.map((res, index) => (
                              <Grid item key={index}>
                                <IconButton
                                  disableRipple
                                  sx={{ padding: '5px !important' }}
                                  onClick={() => setImageUrl(res)}
                                >
                                  <img
                                    src={imageBaseUrl + res}
                                    alt="pic"
                                    style={{
                                      width: '60px',
                                      height: '60px',
                                    }}
                                    className="prod-image-small pointers"
                                  />
                                </IconButton>
                              </Grid>
                            ))
                          : null}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Box className="space-between-container">
                            <Typography className="single-pro-price">
                              RS. {Number(newPrice).toFixed(2)}
                              {!fetchProduct?.minQuantity || Number(fetchProduct?.minQuantity) === 0 ? null : (
                                <Typography className="prodpage-text-body">Rate Per Piece</Typography>
                              )}
                              {!fetchProduct?.minQuantity || Number(fetchProduct?.minQuantity) === 0 ? null : (
                                <Typography className="prodpage-text-body">{`Minimum Quantity: ${fetchProduct?.minQuantity}`}</Typography>
                              )}
                            </Typography>
                            <Checkbox
                              {...label}
                              checked={checkeds}
                              onChange={handleChangeCheckBox}
                              icon={<FavoriteBorderIcon />}
                              checkedIcon={<FavoriteIcon sx={{ color: 'red' }} />}
                            />
                          </Box>
                          <Box width={'100%'}>
                            {!fetchProduct ||
                            Object.keys(fetchProduct).length === 0 ||
                            !fetchProduct?.sizes ||
                            fetchProduct?.sizes.length === 0 ? null : (
                              <Grid item xs={12}>
                                <Typography variant="subtitle2" py={1}>
                                  Sizes
                                </Typography>
                                <Grid container spacing={1} alignItems={'flex-end'}>
                                  {fetchProduct?.sizes.map((resItem, index) =>
                                    !resItem?.title ? null : (
                                      <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                        <button
                                          className={
                                            selectedSize?._id === resItem?._id
                                              ? 'custon-product-border'
                                              : 'custon-product-checkbox'
                                          }
                                          style={{
                                            textDecoration:
                                              resItem?.itemStatus === true || resItem?.itemStatus === 'true'
                                                ? 'line-through'
                                                : 'none',
                                          }}
                                          onClick={() => {
                                            if (selectedSize?._id === resItem?._id) {
                                              setSelectedSize(null);
                                            } else {
                                              setSelectedSize(resItem);
                                            }
                                          }}
                                        >
                                          <p className="custon-product-checkbox-text">{resItem?.title}</p>
                                        </button>
                                      </Grid>
                                    )
                                  )}
                                </Grid>
                              </Grid>
                            )}
                          </Box>
                          <Typography
                            pt={1}
                            gutterBottom
                            variant="body"
                            component="div"
                            sx={{
                              fontWeight: '400',
                              fontFamily: 'Poppins',
                              fontSize: '22px',
                              color: 'rgba(0, 0, 0, 0.5);',
                            }}
                          >
                            <del>RS. {oldPrice}</del>&nbsp;&nbsp;
                            <span style={{ color: 'red' }}> {discount}%off</span>
                          </Typography>
                          <Typography
                            gutterBottom
                            variant="body"
                            component="div"
                            sx={{
                              fontWeight: 'bold',
                              fontFamily: 'Poppins',
                              color: 'rgba(0, 0, 0, 0.5);',
                              marginTop: '30px',
                              marginBottom: '30px',
                            }}
                            className="desc"
                          >
                            Quantity: &nbsp;&nbsp;&nbsp;
                            <span className="desc">
                              <Button
                                variant={counter === 1 ? 'disabled' : 'contained'}
                                className="acc-btn"
                                onClick={decrement}
                                size="small"
                              >
                                -
                              </Button>
                              <TextField
                                value={counter}
                                inputProps={{ style: { padding: '5px' } }}
                                sx={{ width: '100px', paddingX: '5px' }}
                                type="number"
                                onChange={handleCounterChange}
                              />
                              {/* <Button variant="text" className="acc-btn1" size="small">
                        {counter}
                      </Button> */}
                              <Button variant="contained" className="acc-btn" onClick={increment} size="small">
                                +
                              </Button>
                            </span>
                          </Typography>
                          <Grid container className="row-container-wrap-chat" spacing={2}>
                            <Grid style={{ paddingLeft: 4 }} item>
                              <Button onClick={() => (userInfo ? handleChat() : navigate('/login'))}>
                                <ChatIcon fontSize="medium" sx={{ color: '#4E9F0B' }} />
                                <Typography className="prodChatBtnText ">Chat</Typography>
                              </Button>
                            </Grid>
                            <Grid item>
                              <BlueButton onClick={() => handleBuyNow(fetchProduct)} sx={{ fontSize: '10px' }}>
                                Buy Now
                              </BlueButton>
                            </Grid>
                            <Grid item>
                              <GreenButton onClick={() => handleAddToCart(fetchProduct)} sx={{ fontSize: '10px' }}>
                                Add to Cart
                              </GreenButton>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} alignItems={'center'} py={2}>
                            <Grid item xs={12} sm={8}>
                              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                Product Code
                              </Typography>
                              <TextField
                                placeholder="Product Code"
                                disabled
                                value={!fetchProduct?.productCode ? 'N/A' : fetchProduct?.productCode}
                                // InputLabelProps={{
                                //   style: { fontSize: 18,fontWeight: "bold" },
                                //   shrink: true,
                                // }}
                                inputProps={{
                                  style: { fontSize: 12, fontWeight: 'bold', padding: 14 },
                                }}
                                fullWidth
                                InputProps={{
                                  style: {
                                    borderRadius: '50px',
                                  },
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                              <BlueButton
                                sx={{ fontSize: '14px', marginTop: '25px' }}
                                onClick={() => {
                                  const productCode = fetchProduct?.productCode || 'N/A';

                                  // Create a temporary input element
                                  const tempInput = document.createElement('input');
                                  tempInput.setAttribute('type', 'text');
                                  tempInput.setAttribute('value', productCode);
                                  document.body.appendChild(tempInput);

                                  // Select the text in the input element
                                  tempInput.select();

                                  // Execute the copy command
                                  const success = document.execCommand('copy');

                                  // Remove the temporary input element from the DOM
                                  document.body.removeChild(tempInput);

                                  if (success) {
                                    toast.success('Copied Product Code', {
                                      position: 'bottom-left',
                                    });
                                  } else {
                                    console.error('Copying to clipboard failed.');
                                    // Handle the case when the copy command fails
                                  }
                                }}
                              >
                                Copy
                              </BlueButton>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Box sx={{ display: openChat === false ? 'none' : 'block' }} className="bottomright">
                          <ChatDialog
                            productid={fetchProduct && fetchProduct?._id}
                            handleChat={handleChat}
                            openChat={openChat}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid item xs={10} sm={10} md={5} lg={7} xl={7} sx={{ marginLeft: '20px' }}>
                      <br />
                      <Typography className="prod-page-subtitle">Description</Typography>
                      <Typography className="prodpage-text-body">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: fetchProduct?.product_description,
                          }}
                        />
                      </Typography>
                      {fetchProduct?.product_brand_id?.name ? (
                        <Box py={1}>
                          <Typography className="prod-page-subtitle">Brand Name</Typography>
                          <Typography className="prodpage-text-body">{fetchProduct?.product_brand_id?.name}</Typography>
                        </Box>
                      ) : null}
                      <Typography className="prod-page-subtitle">Delivery Days</Typography>
                      <Typography className="prodpage-text-body">{fetchProduct?.delivered_days}</Typography>
                    </Grid>
                  </Grid>
                ) : (
                  []
                )}
              </Grid>
              {/* <Grid> */}
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <Typography className="prodPageSubtitle2 prodReviewTitle prodPageText" align="center">
                  From The Same Store
                </Typography>
              </Grid>
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <Grid container spacing={2}>
                  {fetchSameStore !== null || fetchSameStore !== undefined || fetchSameStore.length > 0
                    ? fetchSameStore?.map((image, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={3} pt={2} key={index}>
                          <ProductCard image={image} />
                        </Grid>
                      ))
                    : null}
                </Grid>
              </Grid>
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <Typography gutterBottom className="prodReviewTitle prodPageText">
                  Products Reviews
                </Typography>
                <Divider className="prodDivider prodReviewDivider" />
              </Grid>

              {/* <Grid sx={{ display: "flex" }}> */}
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container>
                      {currentPosts
                        ? currentPosts?.map((obj, index) => (
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={index}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <Grid item>
                                  <Typography
                                    gutterBottom
                                    variant="body"
                                    component="div"
                                    sx={{
                                      fontWeight: '400',
                                      fontFamily: 'Poppins',
                                      fontSize: '14px',
                                    }}
                                  >
                                    {obj?.name} &nbsp;
                                    <span style={{ color: 'rgba(0, 0, 0, 0.5)' }}>
                                      - {moment(obj?.createdAt).fromNow()}
                                    </span>
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <span>
                                    <Rating
                                      name="read-only"
                                      value={obj?.rating}
                                      size="small"
                                      sx={{ color: '#BECC03;' }}
                                      readOnly
                                    />
                                  </span>
                                </Grid>
                              </Box>
                              <Box display="flex" justifyContent="space-between">
                                <Stack>
                                  <Typography variant="body" className="prodComnt">
                                    {obj?.comment}
                                  </Typography>
                                  <Grid className="prodComntImgContainer" container>
                                    <Grid item>
                                      <img
                                        src={imageBaseUrl + obj?.feedbackImage}
                                        alt="pic"
                                        className="prodImageSmall comntImg"
                                      />
                                    </Grid>
                                  </Grid>
                                </Stack>
                                {obj?.userId?._id === userInfo?._id && editReview && (
                                  <IconButton
                                    disableRipple
                                    onClick={() => {
                                      setIsShown(!isShown);
                                      setRatingValue(obj.rating);
                                      setReviewId(obj._id);
                                      setValues({
                                        message: obj?.comment,
                                      });
                                    }}
                                  >
                                    <EditOutlinedIcon />
                                  </IconButton>
                                )}
                              </Box>

                              <Divider className="prodDivider prodReviewDivider" />
                            </Grid>
                          ))
                        : []}
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="pagination-containerProd" pt={2}>
                        <Pagination
                          count={totalPages} // Show total pages instead of current page
                          page={currentPage} // Set the current page
                          renderItem={(item) => (
                            <PaginationItem
                              components={{
                                previous: ArrowBackIcon,
                                next: ArrowForwardIcon,
                              }}
                              {...item}
                            />
                          )}
                          variant="outlined"
                          shape="rounded"
                          onChange={handleChange}
                        />
                      </Grid>
                      {!editReview && (
                        <Grid className="writeReviewContainer" item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Button
                            variant="contained"
                            endIcon={reviewIcon}
                            className="writeReviewBtn"
                            onClick={() => {
                              handleClick();
                            }}
                          >
                            Write a Review
                          </Button>
                        </Grid>
                      )}
                      {isShown && (
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography className="review-form-title">Your Rating</Typography>
                          <Rating
                            value={ratingValue}
                            name="read-only"
                            size="small"
                            sx={{ color: '#BECC03;', paddingRight: '10px' }}
                            onChange={(event, newValue) => {
                              setRatingValue(newValue);
                            }}
                          />
                          <Box
                            component="form"
                            noValidate
                            autoComplete="off"
                            className="login-form"
                            sx={{ width: '100%' }}
                            spacing={4}
                          >
                            <TextField
                              name="message"
                              id="outlined-multiline-static"
                              label="Your Feedback"
                              value={values?.message}
                              onChange={handleInputChange}
                              multiline
                              helperText={errors.message}
                              rows={4}
                              style={{ width: '100%', fontFamily: 'Poppins' }}
                              error={errors.message}
                            />
                            <Grid sx={{ width: '100%' }}>
                              <Typography className="prodPageText uploadImagTitle">
                                Images: &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                                <Button className="reviewUploadBtn" variant="contained" component="label">
                                  Choose File
                                  <input
                                    hidden
                                    accept=".png, .jpg, .jpeg"
                                    onChange={(e) => setfeedbackImages(e.target.files[0])}
                                    // onChange={onFileChange}
                                    multiple
                                    type="file"
                                  />
                                </Button>
                                {!feedbackImages && fileError && (
                                  <Typography sx={{ color: 'red' }}>File is required</Typography>
                                )}
                              </Typography>
                            </Grid>
                            <Grid sx={{ width: '100%' }} pt={4}>
                              <BlueButton onClick={editReview ? handleEditReview : saveReview}>Send</BlueButton>
                            </Grid>
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Box className="top-container centered">
              <Typography className="prodPageSubtitle2 prodReviewTitle prodPageText" align="center">
                Loading
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default ProductDetail;
